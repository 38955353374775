// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
// require("jquery")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import '../stylesheets/application.scss'
import '@fortawesome/fontawesome-free/js/all'

$(function() {
  $('a[href^="#"]').click(function() {
    var href = $(this).attr('href');
    var target = href == '#' ? 'html' : href;
    var position = $(target).offset().top;

    $('html, body').animate({ scrollTop: position }, 500, 'swing');
    if ($(window).scrollTop() < position) {
      $('#top-btn').css('display', 'block');
    } else {
      $('#top-btn').css('display', 'none');
    }

    return false;
  });
});

$(function() {
  $('.search-btn').on('click', function() {
    $('.search-panel').slideToggle();
  });

  $('.ranking-table__btn').on('click', function() {
    $(this).closest('.ranking-table').find('tr').removeClass('hidden');
    $(this).closest('.ranking-table').find('.ranking-table__overlay').addClass('hidden');
    $(this).addClass('hidden');
  });

  $('.main-btn').on('click', function() {
    $('.site').removeClass('hidden');
    $(this).addClass('hidden');
  });

  $('.job-btn').on('click', function() {
    $('.job').removeClass('hidden');
    $(this).addClass('hidden');
  });
});

$(function() {
  rankingTableOverLay();

  $('.ranking-tab').on('click', function() {
    $(this).siblings('.ranking-tab').removeClass('current');
    $(this).addClass('current');

    $('.ranking-table table').addClass('hidden');
    $($(this).data().target).removeClass('hidden');

    rankingTableOverLay();
  });

  function rankingTableOverLay() {
    var tr = $('.ranking-table table:not(.hidden) tr:not(.hidden)').last();
    if (tr[0]) $('.ranking-table__overlay').height(tr.innerHeight());
  }
});
